@import "~bootstrap/scss/functions";

//@import "variables";

@import "~@solidwhite/swcp-base/lib/sass/index.css";

//@import "~bootstrap/dist/css/bootstrap.min.css";


$primary:   #003595 !important;
$secondary: #e52330 !important;

.swcp-menu-first {
  background: $primary;
}

.bg-primary {
  background-color: $primary;
}

.bg-danger {
  background-color: $secondary;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

.badge-primary {
  background-color: $primary;
}

.spinner-border {
  color: $primary;
}

// Outdated browser page:

#outdated {
  position: fixed;
  pointer-events:none;
  background-color: transparent !important;
  width:100%;
  height:100vh;
  z-index: 1500;
}

#outdated .vertical-center {
  pointer-events: all;
  padding-top: 4rem;
  width:100%;
  height:100vh;
  background-color:white !important;
  color: black !important;
  text-align: center;
}

#outdated h6 {
  color:black !important;
  font-size: 2rem;
}

#outdated p {
  display: none !important;
}

#buttonUpdateBrowser {
  background-color:white !important; 
  display: none !important;
}